import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import RestService from "../Restservice";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography variant="caption">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

export default function FooterSlider({footerMenuData}) {   
    
  const [value, setValue] = React.useState(0);  
  const [footerMenuList, setfooterMenuList] = React.useState(footerMenuData);
  const [status, setStatus] = React.useState(footerMenuList?.length); 
  const storeDate = localStorage.getItem("storeDate")
    
  useEffect(()=>{ 
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let currentDate = mm + '-' + dd + '-' + yyyy;
    
    if((footerMenuList.length === 0) || (storeDate !== currentDate)){ 
        console.log('Reacched Here')
        RestService.getFooterListData(function (res) { 
            const response = JSON.parse(res); 
            console.log('footerMenuList Data',response?.data)
          if (parseInt(response?.data?.status) === 1) {
            localStorage.setItem('storeDate',currentDate);
            localStorage.setItem(
              "footerMenuList",
              JSON.stringify(response.data.footerLink, null)
            );
            setfooterMenuList(response.data.footerLink);
            setStatus(response.data.footerLink?.length)
          }

          
        });
    }   
  },[footerMenuList,storeDate])

  const handleChange = (event, newValue) => {     
    setValue(newValue,status);
  };   

  const width = 40;
  const widthModifier = {width: `${width}px`,minWidth:`${width}px`,heigth:'30px'};

  return (<>  {footerMenuList && <>
    <div className='symbol-slider'> 
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label={'All'} {...a11yProps(0)} style={widthModifier} />
            {footerMenuList && footerMenuList.map((item,key)=>(
                <Tab key={`tab-key-${key}`} label={item.label} {...a11yProps(item.label)} style={widthModifier} />
            ))} 
          </Tabs>
        </AppBar>
        {footerMenuList && footerMenuList.map((item,key)=>(
                 <TabPanel key={`panelKey${key}`}  value={value} index={key+1}>
                    <h3 className="head-title">{item.label}</h3>
                    <div className='stock-list-grid'>
                    {item.child && item.child.map((items,keys)=>(
                        <Link key={`panelKey${keys}`} to={`/stocks/${items.symbol}`}>{items.name} </Link>
                    ))}
                    </div>
                </TabPanel>
        ))} 
        {footerMenuList && footerMenuList.map((item,key)=>(
                 <TabPanel key={`panelAllKey${key}`}  value={value} index={0}>
                    <h3 className="head-title">{item.label}</h3>
                    <div className='stock-list-grid'>
                    {item.child && item.child.map((items,keys)=>(
                        <Link key={`panelAllDetailKey${keys}`} to={`/stocks/${items.symbol}`}>{items.name} </Link>
                    ))}
                    </div>
                </TabPanel>
        ))} 
        
    </div>
    </>}
    </>
  )
}
